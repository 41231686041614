
<template>
  
	<body>
    <div class="collect-container"ref="target">
    <div id="heading">
      <h1 style="z-index: 999;">FUTURE VISION LAB</h1>
     
    </div>
  </div>
	</body>
 

</template>



<script setup>
import { ref, onMounted } from 'vue';
// import {Scene} from 'three';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// import {gsap} from "gsap";
// import {ScrollTrigger} from "gsap/ScrollTrigger";

const renderer = new THREE.WebGLRenderer({ antialias: true });
renderer.outputColorSpace = THREE.SRGBColorSpace;

renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setClearColor(0x000000);
renderer.setPixelRatio(window.devicePixelRatio);

renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;

document.body.appendChild(renderer.domElement);

const target = ref();

const scene = new THREE.Scene();

const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
 camera.position.set(10,20, 11);


const controls = new OrbitControls(camera, renderer.domElement);
controls.enableZoom = false;
controls.enableDamping = true;
controls.enablePan = false;
controls.minDistance = 5;
controls.maxDistance = 20;
controls.minPolarAngle = 0.5;
controls.maxPolarAngle = 1.5;
controls.autoRotate =false;
controls.target = new THREE.Vector3(0, 1, 0);
controls.update();

const groundGeometry = new THREE.PlaneGeometry(20, 20, 32, 32);
groundGeometry.rotateX(-Math.PI / 2);
const groundMaterial = new THREE.MeshStandardMaterial({
  color: 0x000000,
  side: THREE.DoubleSide
});
const groundMesh = new THREE.Mesh(groundGeometry, groundMaterial);
groundMesh.castShadow = false;
groundMesh.receiveShadow = true;
scene.add(groundMesh);

const spotLight = new THREE.SpotLight(0xffffff, 3000,100, 2, 1);
spotLight.position.set(0, 25, 0);
spotLight.castShadow = false;
spotLight.shadow.bias = -0.0001;
scene.add(spotLight);

const al = new THREE.AmbientLight(0xffffff,3);
scene.add(al);

const planes = new THREE.Group();
const geometry = new THREE.PlaneGeometry(8, 6);
const material = new THREE.MeshPhongMaterial( {map:new THREE.TextureLoader().load("textures/about.jpg"),side: THREE.DoubleSide} );

const plane = new THREE.Mesh( geometry, material );
plane.position.set(0, 3, -3);



const geometry1 = new THREE.PlaneGeometry(8, 6);
const material1 = new THREE.MeshPhongMaterial( {map:new THREE.TextureLoader().load("textures/work1.jpeg"),side: THREE.DoubleSide } );

const plane1 = new THREE.Mesh( geometry1, material1 );
plane1.position.set(0, 3, -1);



const geometry2 = new THREE.PlaneGeometry(8, 6);
const material2 = new THREE.MeshLambertMaterial( {map:new THREE.TextureLoader().load("textures/Home.jpg"),side: THREE.DoubleSide} );
const plane2 = new THREE.Mesh( geometry2, material2 );
plane2.position.set(0,3,1);


const geometry3 = new THREE.PlaneGeometry(8, 6);
// const material3 = new THREE.MeshLambertMaterial( {color: 0xffff00, side: THREE.DoubleSide,shininess:200} );
const material3 = new THREE.MeshLambertMaterial( {map:new THREE.TextureLoader().load("textures/work3.jpeg"),side: THREE.DoubleSide} );
const plane3 = new THREE.Mesh( geometry3, material3 );
plane3.position.set(0,3,3);


const geometry4 = new THREE.PlaneGeometry(8, 6);
// const material3 = new THREE.MeshLambertMaterial( {color: 0xffff00, side: THREE.DoubleSide,shininess:200} );
const material4 = new THREE.MeshLambertMaterial( {map:new THREE.TextureLoader().load("textures/work4.jpeg"),side: THREE.DoubleSide} );
const plane4 = new THREE.Mesh( geometry4, material4);
plane4.position.set(0,3,5);



const geometry5 = new THREE.PlaneGeometry(8, 6);
// const material3 = new THREE.MeshLambertMaterial( {color: 0xffff00, side: THREE.DoubleSide,shininess:200} );
const material5 = new THREE.MeshLambertMaterial( {map:new THREE.TextureLoader().load("textures/work4.jpeg"),side: THREE.DoubleSide} );
const plane5 = new THREE.Mesh( geometry5, material5);
plane5.position.set(0,3,7);

planes.add(plane);
planes.add(plane1);
planes.add(plane2);
planes.add(plane3);
planes.add(plane4);
planes.add(plane5);
scene.add(planes);

const raycaster = new THREE.Raycaster();
// document.addEventListener('click',onClick);
let x;
const xFinal = 12;

// let hoverTL = gsap.timeline();

// hoverTL.to(plane1,scale(16,12))


// function onClick(event){
//   const coords = new THREE.Vector2(
//     (event.clientX / renderer.domElement.clientWidth)*2 - 1,
//      -((event.clientY / renderer.domElement.clientHeight)*2 - 1),
//   );
//    raycaster.setFromCamera(coords,camera);
//    const intersections = raycaster.intersectObjects(planes.children,true);
//    if(intersections.length>0){
//      const selectedObject = intersections[0].object;
//    x = camera.position.x;
//   selectedObject.scale.set(1.2,1.2);
//    selectedObject.position.set(5,3);
//     console.log(`was clicked!`);
//     console.log({intersections});
//    }

// }





/////////////以下Hover備用

document.addEventListener('mousemove',onmousemove);

function onmousemove(event){
  const pointer = new THREE.Vector2
  var raycaster = new THREE.Raycaster();
  pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
  pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

  raycaster.setFromCamera(pointer,camera);
  const intersects =  raycaster.intersectObjects(planes.children);
console.log(intersects);
  // for(let i=0; i < intersects.length; i++){
  //   console.log(intersects)
  //   const hoveredObject = intersects[i].object;
  //   hoveredObject.scale.set(1.2,1.2);
  //   hoveredObject.position.set(2,3);
  // }
  if(intersects.length > 0){
    var i=0;
    const hoveredObject = intersects[i].object;
    hoveredObject.scale.set(2,2);
    hoveredObject.position.set(2,3);
  }
  
}

function resetMaterials(){
  for (let i =0; i< scene.children.length;i++){
    if(scene.children[i].material){
      scene.children[i].material.opacity = 1.0
    }
  }
}





const axis = new THREE.AxesHelper(10);
scene.add(axis);







window.addEventListener('resize', () => {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});

function animate() {
  x += 0.1;
  if(x< xFinal)
  camera.position.x = x;
if(x<0)
camera.position.x = x 
  requestAnimationFrame(animate);
  controls.update();
  renderer.render(scene, camera);

  resetMaterials();
}


onMounted(() => {
  
  target.value.appendChild(renderer.domElement);

  
  
  animate();
});



</script>



<style>
.h1 {
  color:white;
  font-size: 4em;
  margin: 0px;
  font-weight: 100;
}
.collect-container{
  position: relative;
  width: 100vw;
  height: 100vh;
}
#heading{
  margin-top: 5rem;
  opacity: 0;
  position:absolute; 
  padding:32px;
  display: flex;
  flex-wrap: wrap;
  flex-direction:column;
  pointer-events: none;
  align-items: center;
  top: 0;
}


.card{
  width: 300px;
  height:auto;
  pointer-events: fill;
  /* margin-top: 2em; */
}
.gsap-container{
  width: 100%;
  height: 100%;
  margin-bottom: 2em;
}
.change{
  border-radius: 20px;
  cursor: pointer;
   text-transform: uppercase;
   background-color:white; 
   border: 0;
   color:black;
   padding: 0.5rem 1rem;
   margin-top: 2em;
}
.change:hover{
  background-color: black;
  color:white;
}
.shadow{
  margin-top: -45px;
}


@media only screen and (min-width:1024px) {
	#heading{
    margin-left:10%;
  }
}





</style>
