<template>
  <div class="about" style="overflow-x: hidden;">
<!-- ---------CSS animation About us-------------- -->
  <!-- -----------------CSS animation About us end ------------------- -->

  <!-- <div class="space_title">
   <div class="title_background">
    <div class="title">
    <h6 style="margin-bottom: 0;font-weight:800px;">關於作品</h6>
    </div>
   </div>
  </div> -->
 <section class="pg-about section-padding sub-bg">

  <div class="title_img_intro_wrap" >

    <div class="container" style="margin: 0;">

  <div class="space_title">
   <div class="title_background">
    <div class="title">
    <h6 style="margin-bottom: 0;font-weight:800px;">關於作品</h6>
    </div>
   </div>
  </div>
  </div>


  <template v-if="this.thirdImage == null">
    <div class="container2" style="height:auto; flex-direction: column;justify-content: center;align-items:flex-start;">
           <div class="pic1" style="width: 100%;">
               <div class="space_img1">
                    <div class="bg-img radius-10 md-mb50" style="display: flex;justify-content: flex-start;height:auto;">
                    <img class="first-image" :src="'https://unzip-clab-api.clab.org.tw/'+ this.firstImage">
                    </div>
              </div>
          </div>

            <div class="img_1" style="width:40%;height:auto;">
              <img class="second-image" :src="'https://unzip-clab-api.clab.org.tw/'+ this.secondImage" style="width: 100%;height: 100%;object-fit:cover;">
               <!-- <img id="aboutImage" style="width: 100%;height:100%;object-fit: cover;"> -->
            </div>
        
    </div>
    </template>

  <template v-else-if="this.secondImage == null">
    <div class="container2">
           <div class="pic1">
               <div class="space_img1">
                    <div class="bg-img radius-10 md-mb50" style="display: flex;justify-content: flex-start;height:100%;">
                    <img class="first-image" :src="'https://unzip-clab-api.clab.org.tw/'+ this.firstImage">
                    </div>
              </div>
          </div>
    </div>
    </template>

    <template v-else>
    <div class="container2">
           <div class="pic1">
               <div class="space_img1">
                    <div class="bg-img radius-10 md-mb50" style="display: flex;justify-content: flex-start;height:100%;">
                    <img class="first-image" :src="'https://unzip-clab-api.clab.org.tw/'+ this.firstImage">
                    </div>
              </div>
          </div>

          <div class="imgs">
            <div class="img_1">
              <img class="second-image" :src="'https://unzip-clab-api.clab.org.tw/'+ this.secondImage" style="width: 100%;height: 100%;object-fit:cover;">
               <!-- <img id="aboutImage" style="width: 100%;height:100%;object-fit: cover;"> -->
            </div>
            <div class="img_2" style="width:100%;height:50%;">
              <img class="third-image" :src="'https://unzip-clab-api.clab.org.tw/'+ this.thirdImage" style="width: 100%;height: 100%;object-fit:cover;">
              <!-- <img id="aboutImage2" style="width:100%;height:100%;object-fit: cover;"> -->
            </div>
          </div>
    </div>
    </template>

    <!-- <div class="artist-container">
            <div class="artist-img" style="width:25%;height:100%;background-color: aqua;"></div>
          </div> -->
 <div class="title-content"style="display:flex;flex-direction: column;width: 90%;margin-top: 2rem;padding: 0.5rem;margin-left: 0.5em;">
          <p class="org-description is-size-4" style="font-weight:bold;color:black;text-align: left;">{{ $route.params.title }}</p>
          <div class="hastag_buttons" style="width:100%; display: flex;flex-direction: row;align-items: flex-start;justify-content:flex-start;gap: 10px;">
              <button class="tag">2024</button>
              <button class="tag">DOME2.0</button>
              <button class="tag">策劃邀請</button>
          </div>
          </div>
    <div class="container3">
      <div class="about_artwork">
        <!-- <div v-html="apiData7" style="color:black;margin-top: 1rem;"></div> -->
        <div v-html="htmlContent" style="color:black;padding:0.5rem;"></div>
      </div>

      <div class="about_artist" style="width: 50%;height: auto;position: relative;">
        <!-- <p class="org-description is-size-4" style="color:black;text-align: left;font-size:0.2rem">{{ $route.params.artist}}</p> -->
        <div v-html="artistContent" style="color:black;position: absolute;font-weight: bolder;padding: 0.5rem;"></div>
      </div>
    </div>

    </div>
</section>

    <hr style="color: aliceblue;">
    <info-bar></info-bar>
    <foot-bar2></foot-bar2>
</div>

</template>


<script>
import axios from 'axios';
import { InfoBar, FootBar2} from '../components';

export default {
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    word: {
      type: String,
    },
    photo:{
      type:String
    }
  },
  name: 'News',
  components: {
     'info-bar':InfoBar,
     'foot-bar2':FootBar2
  },
  data() {
    return {
      msg: 'FUTURE VISION LAB ',
      firstImage:{},
      schedules:[],
      apiData1:{},
      apiData2:{},
      apiData3:{},
      apiData4:{},
      apiData5:{},
      apiData6:{},
      apiData7:{},
      firstImage:this.firstImage,
      secondImage:this.secondImage,
      thirdImage:this.thirdImage,
    };
  }, 
  mounted(){
    // let index = this.$route.query.index 
    // this.index = index
    console.log(this.$route.params.photo)
    var firstImage = this.$route.params.photo
    this.firstImage  = firstImage;

    var secondImage = this.$route.params.photo2
    this.secondImage = secondImage;

    var thirdImage = this.$route.params.photo3
    this.thirdImage = thirdImage;


  //   axios.all([
  //     axios.get('https://unzip-clab-api.clab.org.tw/api/v1/activities?search=FUTURE%20VISION%20LAB%202024'),
  //     axios.get('https://unzip-clab-api.clab.org.tw/api/v1/schedules/4'),
  //     axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works/6162e143-f315-4a45-a9b4-830c8b3d8704'),
  //     axios.get('https://unzip-clab-api.clab.org.tw/activity-photo/dW5kZWZpbmVkLTE2OTE1NzcyMTAyMzA=.jpeg',{responseType:'blob'}),
  //     axios.get('https://unzip-clab-api.clab.org.tw/activity-photo/dW5kZWZpbmVkLTE2ODI0MTc0NjgwNDM=.jpeg',{responseType:'blob'}),
  //     axios.get('https://unzip-clab-api.clab.org.tw/api/v1/activities/443e7a5e-6d51-4d39-9bc0-fd71e579da09'),
  //     axios.get('https://unzip-clab-api.clab.org.tw/api/v1/works/87db0364-e198-4abb-b4e9-d6c3c70ebd68'),
  //     axios.get('https://unzip-clab-api.clab.org.tw/work-photo/1ae1a839-6db4-42f8-a6e3-831c59623ca7/MDIuU19fMjQxNjY0MTEuanBnLTE2MzMxNzA4MDYzNDgtMzU4Mw==.jpeg',{responseType:'blob'})
  //   ])
  //   .then(axios.spread( (response1, response2 ,response3,response4,response5,response6,response7,response8) => {
  //      //  const reader = FileReader();
  //     //宣告Filereader
  //     this.apiData1 = response1.data;
  //     this.apiData2 = response2.data;
  //     this.apiData3 = response3.data;
  //     this.apiData4 = response4.data;
  //     this.apiData5 = response5.data;
  //     this.apiData6 = response6.data;
  //     this.apiData7 = response7.data.work_zh.proposal;
  //     this.apiData8 = response8.data;
  //     let imageNode = document.getElementById('aboutImage');
  //     let imageNode2 = document.getElementById('aboutImage2');
  //     let imgUrl = URL.createObjectURL(response5.data);
  //     let imgUrl2 = URL.createObjectURL(response8.data);
  //     imageNode.src = imgUrl;
  //     imageNode2.src = imgUrl2;
      
  //     console.log(this.apiData1);
  //     console.log(this.apiData2);
  //     console.log(this.apiData3);
  //     console.log(this.apiData4);
  //     console.log(this.apiData5);
  //     console.log(this.apiData6);
  //     console.log(this.apiData7);
  //     console.log(this.apiData8);
  //   }))
  //  .catch(err=>{
  //    console.log(err.response);
  //    });
  },


  
  computed: {
    htmlContent() {
      // 从路由参数获取内容
      return this.$route.params.word || '';
    },
    artistContent() {
      // 从路由参数获取内容
      return this.$route.params.artist || '';
    }
    
  }
}
</script>
<style lang="scss" scoped>
.title_img_intro_wrap{
  width: 80%;
  display: flex;
  flex-direction:column;
  margin: 0 auto;
}
  .about {
    background-color: rgb(255, 255, 255);
    overflow-y: hidden;
  }
  .space_title{
    // padding: 10px 10px;
    position: relative;
    width:100%;
    // margin-left:auto;
    // margin-right:auto;
    margin-top:6rem;
  }
 @media only screen and (max-width:768px){
  .space_title{
    width:90%;
    margin-top: 5rem;
  }
  .container3{
    height: 100vh;
    flex-direction: column;
  }
  .about_artwork{
    width: 100%;
  }
 }

  .title_background{
    width: 6rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: larger;
    background-color: #000000;
    // color: rgb(255, 0, 0) !important;
    border-radius: 40px;

  }
  .title{
    color: white !important;
  }
  .hero-body{
    width: 100%;
    height: auto;
    background-color: rgb(255, 255, 255);
    display:block;
    position: relative;
    overflow: hidden;
  }
  .aboutImage{
    display: block;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  
  .container{
    max-width: 960px;
    width: 100%;
    padding-right:var(--bs-gutter-x, .75rem);
    padding-left:var(--bs-gutter-x,.75rem);
    margin-right:auto;
    margin-left:auto;
    margin-top: 1rem;
  }
  .container2{
    width:100%;
    padding-right:var(--bs-gutter-x, .75rem);
    padding-left:var(--bs-gutter-x,.75rem);
    margin-right:auto;
    margin-left:auto;
    margin-top: 1rem;
    height:400px;
    display: flex;
    gap:10px;
  }
  .container3{
    width:100%;
    height: auto;
    padding-right:var(--bs-gutter-x, .75rem);
    padding-left:var(--bs-gutter-x,.75rem);
    margin-right:auto;
    margin-left:auto;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
  }
  // .artist-container{
  //   width:100%;
  //   padding-right:var(--bs-gutter-x, .75rem);
  //   padding-left:var(--bs-gutter-x,.75rem);
  //   margin-right:auto;
  //   margin-left:auto;
  //   margin-top: 1rem;
  //   height:25vh;
  //   display: flex;
  //   gap:10px;
  // }
  .about_artwork{
    width:50%;
    height: auto;
    right: 0;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
  }
  .tag{
    width:auto;
    height:1.5rem;
    background-color: aliceblue;
    border:1px solid black;
    color: black;
    border-radius:30px;
  }
  .tag:hover{
    background-color: #000000;
    color: aliceblue;
  }
  .space_img1{
    // .col-lg-4
    flex:0 0 auto;
    // width:33.33333333%;
    width:100%;
  }
  .first-image{
    width: 100%;
    height:400px;
    object-fit:cover;
  }
  .col-lg-8{
    flex:0 0 auto;
    width:66.66666667%;
  }

  img{
    // border-radius: 10px;
    height:290px;
  }
  // .md-mb50{
  //   margin-bottom: 90px !important;
  // }
  .pg-about .bg-img{
    height:220px;
    background-size:cover;
    background-position:center center;
  }
  .slider{
    animation-name:future-vision-lab;
    animation-duration:150s;
    animation-iteration-count:infinite;
    animation-timing-function: linear;
    animation-delay: 0;
    display: flex;
  }
  .item{
    position: relative;
    padding: 0 30px;
    display: flex;
  }
 
  @keyframes future-vision-lab{
    0%{
      transform: translateX(0);
    }
    100%{
      transform: translateX(-100%);
    }
  }
  .videobox{
    position: relative;
    margin-bottom: 5rem;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

  }

  .videobox iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.space_imgs{
  width:70vw;
  height:40vh;
  display:flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-start;
  margin-left:auto;
  margin-right: auto;
  margin-top:5rem;
  margin-bottom: 5rem;
}
.imgs{
  width: 30%;
  height:400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.img_1{
  width:100%;
  height:50%;
}
.images{
  width:50%;
  height:50%;
  position: relative;
  display: flex;
  align-items: center;
  // left:10%;
}


@media only screen and (max-width:728px){
  .title_img_intro_wrap{
     width:100%;
  }
  .container2{
    flex-direction: column;
    height: auto;
    align-items:center;
    justify-content: center;
  }
  .first-image{
    object-fit: contain;
    height: auto;
  }
  .row{
    width: 100%;
    margin: 0 auto;
  }
  .container3{
    flex-direction: column;
  }
  .about_artwork{
    width: 100%;
  }
  .space_imgs{
    width: 90vw;
    height: 50vh;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    margin-top:2rem;
  }
  .space_img1{
    padding: 0 !important;
  }
  .aboutImage{
    width: 100%;
    height: 100%;
  }
  .images{
    width: 100%;
    height:100%;
    left:10%;
  }
  .imgs{
    flex-direction: row;
    width: 100%;
    height: auto;
  }
  .img_1{
    height: auto;
  }
}




</style>
<style lang="css" scoped src="../bootstrap.min.css">
</style>

