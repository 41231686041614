<template>
   <!-- <section class="hero app-navbar is-bold">
    <div class="hero-head"> -->
      <header class="navbar is-bold">
        <div class="container">
           <div class="navbar-brand">
             <a class="navbar-item">
               <img src="../assets/FVL_logo.png">
                  <!-- <h3 style="margin-bottom: 0;"> <a style="color: aliceblue;"> FUTURE VISION LAB</a></h3> -->
             </a>
       
              <div class="navbar-burger burger" data-target="navbarDropdown" style="color:gray;">
                   <span></span>
                   <span></span>
                   <span></span>
                   <span></span>
                   <span></span>
                   <span></span>
              </div>
        </div>

        <div id="navbarDropdown" class="navbar-menu" >
          <div class="navbar-start" style="color: black;">
            <a class="navbar-item" href="#" style="font-size:16px;">
              <router-link :to="{ name: 'Home' }">首頁/HOME</router-link>
            </a>
            <!-- <a class="navbar-item" href="#" style="font-size:16px;">
              <router-link :to="{ name: 'Fvlhome' }">FVL未來藝術節</router-link>
            </a> -->
            <a class="navbar-item" href="#" style="font-size:16px;">
              <router-link :to="{ name: 'fvl-fest-2024' }">FVL未來藝術節</router-link>
            </a>
            <a class="navbar-item" href="#" style="font-size:16px;">
              <router-link :to="{ name: 'About' }">未來視覺實驗室/ABOUT</router-link>
            </a>
            <a class="navbar-item" href="#" style="font-size:16px;">
              <router-link :to="{ name: 'News' }">關於空間/OUR SPACE</router-link>
            </a>
 <!-- ----------------------------以下div是FVL Collection------------------------------------------------------------------- -->
            <!-- <a class="navbar-item" href="#" style="font-size:16px;">
              <router-link :to="{ name: 'Collection' }">FVL COLLECTION</router-link>
            </a> --> 
<!-- ----------------------------以下div是過去作品------------------------------------------------------------------- -->
            <!-- <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-item" href="#" style="font-size:16px;">
             <router-link :to="{ name: 'All' }">過去作品/ARCHIVE</router-link>
            </a>
              <div class="navbar-dropdown is-boxed">
                <a class="navbar-item" href="#" style="color: black;">
                  <router-link :to="{ name: 'All' }" style="color: black;">全部年份</router-link>
                </a>
                <a class="navbar-item" href="#">
                  <router-link :to="{ name: '2018' }" style="color: black;">2018</router-link>
                </a>
                <a class="navbar-item" href="#">
                  <router-link :to="{ name: '2019' }" style="color: black;">2019</router-link>
                </a>
                <a class="navbar-item" href="#">
                  <router-link :to="{ name: '2020' }" style="color: black;">2020</router-link>
                </a>
                <a class="navbar-item" href="#">
                  <router-link :to="{ name: '2021' }" style="color: black;">2021</router-link>
                </a>
                <a class="navbar-item" href="#">
                  <router-link :to="{ name: '2022' }" style="color: black;">2022</router-link>
                </a>
                <a class="navbar-item" href="#">
                  <router-link :to="{ name: '2023' }" style="color: black;">2023</router-link>
                </a>
                <a class="navbar-item" href="#">
                  <router-link :to="{ name: '2024' }" style="color: black;">2024</router-link>
                </a>
                 <a class="navbar-item is-active" href="#"> 
                   Item is-active 
                 </a> 
              </div>
            </div> -->
          </div>

          <div class="navbar-end">
          <div class="navbar-item">
              <!-- <a class="navbar-item" href="https://github.com/hkdb/vuejs-quickstart" target="_blank">
                <b-icon pack="fa" icon="github" type="is-black"></b-icon>
              </a> -->
            </div>
            <div class="navbar-item">
              <div class="field is-grouped">
              </div>
            </div>
          </div>
        </div>
        </div>
      </header>
    <!-- </div>
  </section> -->
</template>

<script>
// import {CAlert} from '@coreui/coreui'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import {CNavbar} from '@coreui/coreui'
export default {
  data:{
    firstname:'home',
    activeDropdown:null,
    visibleOffcanvas: false,
     
  },
  // methods:{
  //  makeActive:function(item){
  //   this.active = item;
  //  }
  // }
}


/* burger navigation */
document.addEventListener('DOMContentLoaded', function () {
  // Get all "navbar-burger" elements
  var $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach(function ($el) {
      $el.addEventListener('click', function () {
        // Get the target from the "data-target" attribute
        var target = $el.dataset.target
        var $target = document.getElementById(target)
        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        $el.classList.toggle('is-active')
        $target.classList.toggle('is-active')
        console.log('is active')
        document.getElementById("navbarDropdown").style.color = "black";
      })
    })
  }


})

</script>

<style lang="scss" scoped>
.navbar {
  background-color: rgb(0, 0, 0);
  position:fixed;
  width: 100%;
  top: 0;
  height: 4rem;
}
.navbar-item{
  border-radius:0;
}


</style>
