<template>
  <section class="info-bar">
    <div class="hero is-dark">
      <div class="hero-body" style="background-color: black;">
        <div class="container">
          <h1 class="title">
            <!-- Start building awesome sites -->
          </h1>
          <h2 class="subtitle">
            <!-- Today! -->
          </h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'info-bar'
}
</script>

<style lang="scss" scoped>
</style>
